<template>

  <div>

    <b-card

      title="Edit configuration"
    >

      <b-form @submit.prevent>
        <b-row>

          <b-col cols="6">
            <b-form-group
              label="BigQuery DataSet"
              label-for="v-bqDataSet"
            >
              <b-form-input
                id="v-bqDataSet"
                v-model="settings.bqDataset"
                placeholder="BigQuery DataSet"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="BigQuery Table"
              label-for="v-bgTable"
            >
              <b-form-input
                id="v-bqTable"
                v-model="settings.bqTable"
                placeholder="BigQuery Table"
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">

            <b-form-group
              label="Run at(UTC time)"
              label-for="v-runAt"
            >
              <b-form-input
                id="v-runAt"
                v-model="settings.runAt"
                placeholder="Run at"
                required
              />
            </b-form-group>

          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Status"
              label-for="v-status"
              required
            >
              <b-form-select
                v-model="settings.active"
                :options="statusOptions"
              />

            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="updateSettings"
            >
              Save settings
            </b-button>

          </b-col>

        </b-row>
      </b-form>

    </b-card>

    <b-card-code
      no-body
      title="Logs  "
    >

      <b-overlay
        :show="loading"
        rounded="sm"
      >

        <b-table
          striped
          hover
          :items="logs"
          :fields="fields"
        >

          <template #cell(id)="data">
            <span v-b-tooltip.hover.top="data.item.id">
              #
            </span>
          </template>

          <template #cell(status)="data">

            <b-badge :variant="data.item.status== 'error' ? 'danger' : data.item.status">
              {{ data.item.status }}
            </b-badge>

          </template>

          <template #cell(createdAt)="data">
            {{ data.item.createdAt|formatDateTime }}
          </template>

        </b-table>

        <div
          v-if="rows > 25"
          class="mt-1 d-flex justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            hide-goto-end-buttons
            :total-rows="rows"
            :per-page="perPage"
            @change="handlePageChange"
          />
        </div>

      </b-overlay>

    </b-card-code>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BCard, BCol, BForm, BRow, BBadge, BFormGroup, BFormInput,
  BFormSelect,
  BFormCheckbox, BButton,
  BPagination, VBTooltip, BOverlay,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BCardCode,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BTable,
    BButton,
    BPagination,
    BOverlay,
    BBadge,
    ToastificationContent,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      settings: {},
      loading: false,
      currentPage: 1,
      accountId: null,
      logs: [],
      perPage: 25,
      fields: ['id', 'label', 'status', 'details', 'createdAt'],
      current: 1,
      rows: 0,
      statusOptions: [
        { value: true, text: 'Active' },
        { value: false, text: 'Disabled' },
      ],
    }
  },

  mounted() {
    this.loadSettings()
    this.loadLogs('skuvault_quantities', 1)
  },

  methods: {

    handlePageChange(value) {
      this.page = value
      this.loadLogs('skuvault_quantities', this.page)
    },

    loadLogs(key, page = 1) {
      this.loading = true
      this.$store.dispatch('app/fetchLogs', { key, page })
        .then(result => {
          this.logs = result.data.docs
          this.currentPage = result.data.page
          this.rows = result.data.totalDocs
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while loading items', 'danger')
        }).finally(() => {
          this.loading = false
        })
    },

    loadSettings() {
      this.$store.dispatch('appSetting/getByKey', 'skuvault_quantities')
        .then(result => {
          this.settings = result.data
        },
        () => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while loading settings', 'danger')
        })
    },
    updateSettings() {
      const params = {
        value: this.settings,
        key: 'skuvault_quantities',
      }

      this.$store.dispatch('appSetting/updateByKey', params)
        .then(() => {
          this.showToast('Success', 'Item was updated successfully', 'success')
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while updating item', 'danger')
        })
    },

    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          text,
          variant,
        },
      })
    },

  },
}
</script>
